import React from "react";
import { TouchableOpacityProps } from "react-native";
import { Container, Title } from "./styles";
import { ServiceCardState } from "../../types";

type Props = TouchableOpacityProps & ServiceCardState;

export default function ServiceCard({
  id: title,
  scheduledStart: time,
  ...rest
}: Props): JSX.Element {
  return (
    <Container {...rest}>
      <Title>{title}</Title>
      <Title>{time}</Title>
    </Container>
  );
}
