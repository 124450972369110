import React from "react"
import { View, StyleProp, ViewStyle } from "react-native"

// Libraries
import { Picker } from "@react-native-picker/picker"

// Components
import FloatingLabel  from "../components/FloatingLabel"
import { Theme } from "../styles"
import { CategoryState, Option } from "../types"

// Constants

interface DropdownProps {
  selectedValue: string | number
  values: CategoryState<string | number>
  onValueChange: (itemValue: string, itemIndex: number) => void
  label: string
  style?: StyleProp<ViewStyle>
}

const Dropdown = ({ selectedValue, values, onValueChange, label, style }: DropdownProps) => {
  return (
    <View style={style}>
      <FloatingLabel label={label} />
      <Picker
        selectedValue={selectedValue}
        style={{
          fontSize: Theme.sizes.fontSizeM,
          color: Theme.colors.text_dark,
          fontWeight: "400",
          height: Theme.sizes.heightS,
          borderRadius: Theme.sizes.borderRadiusS,
          borderColor: Theme.colors.border,
          backgroundColor: Theme.colors.title,
          paddingHorizontal: 5,
        }}
        itemStyle={{ fontSize: Theme.sizes.fontSizeM, color: Theme.colors.text_dark, fontWeight: "400" }}
        onValueChange={onValueChange}
      >
        {values.map((item: Option<string>, index: number) => (
          <Picker.Item label={item.label} value={item.value} key={index} />
        ))}
      </Picker>
    </View>
  )
}

export default Dropdown