import { I18n } from "i18n-js";
import * as Localization from "expo-localization";
import en from "./locales/en";
import pt from "./locales/pt";
import es from "./locales/es";

const i18nJs = new I18n();

interface MetaDictionary<T> {
  screens: {
    NotFound: {
      message: T;
    };
    Measures: {
      scale: T;
      category: T;
      container: T;
      device: T;
      measurementType: T;
      tare: T;
      submit: T;
      dashboard: T;
      newMeasurement: T;
      volume: T;
      dimensions: T;
      time: T;
      download3DView: T;
      errorMessageSubmit: T;
      errorMessageUnknownDevice: T;
      errorMessageUnknownContainer: T;
      errorMessageUnboundTimeout: T;
      errorMessageUnauthorizedRequest: T;
      errorMessageDeviceNotReady: T;
      errorMessageVolumeComputation: T;
      errorMessageUnexpected: T;
      errorMessageUnknownUsecase: T;
      errorMessageUnboundDelay: T;
      errorMessageInvalidContentType: T;
    };
    Home: {
      emptyServiceList: T;
      emptyCompletedServices: T;
      scheduled: T;
      completed: T;
      version: T;
      helpPage: T;
      help: T;
    };
    CreateService: {
      header: T;
      orderNumber: T;
      register: T;
      cancel: T;
    };
    ServiceCategories: {
      category: T;
      measured: T;
      completeService: T;
      emptyCategoriesList: T;
    };
    ServiceMeasurements: {
      declared: T;
      measured: T;
      measurement: T;
      volume: T;
      newMeasurement: T;
      emptyMeasurementsList: T;
      container: T;
      device: T;
    };
  };
  cognito: {
    SignIn: T;
    SignOut: T;
    Username: T;
    Password: T;
    Email: T;
    Confirm: T;
    Cancel: T;
    Submit: T;
    Skip: T;
    Verify: T;
    Code: T;
    Anonymous: T;
    IncorrectLogin: T;
    ChangePassword: T;
    OldPassword: T;
    NewPassword: T;
    ConfirmPassword: T;
    ForgotPassword: T;
    EmailConfirmCode: T;
    ConfirmationCode: T;
    FirstAccess: T;
    SuccessMessageChangePassword: T;
    SuccessMessageSubmit: T;
    SuccessMessageTara: T;
    ErrorMessageDifferentPasswords: T;
    ErrorGeneralMessage: T;
    ErrorEmptyFieldsMessage: T;
    NotAuthorizedException: T;
    UserNotFoundException: T;
    CodeMismatchException: T;
    InvalidPasswordException: T;
    InvalidParameterException: T;
    PasswordResetRequiredException: T;
  };
  popups: {
    errors: {
      computationOngoing: T;
      computationTimeout: T;
      downloadErrorTitle: T;
      downloadErrorText: T;
      fetchErrorTitle: T;
      fetchErrorText: T;
      registrationErrorTitle: T;
      registrationErrorText: T;
      updateErrorTitle: T;
      updateErrorText: T;
      measurementErrorTitle: T;
      measurementDeleteErrorTitle: T;
      measurementDeleteErrorText: T;
      measurementPatchErrorTitle: T;
      measurementPatchErrorText: T;
      orderNumberCanNotBeEmpty: T;
      orderNumberTooLong: T;
      volumeCanNotBeEmpty: T;
      volumeHasInvalidChars: T;
      volumeInWrongFormat: T;
      volumeInvalid: T;
      volumeOutOfBounds: T;
    };
    success: {
      volume: T;
      deletedVolumeTitle: T;
    };
  };
}

export type Dictionary = MetaDictionary<string>;

const t = (key: string | string[]) => () => i18nJs.t(key);

const i18nDictionary: MetaDictionary<() => string> = {
  screens: {
    NotFound: {
      message: t("screens.NotFound.message"),
    },
    Measures: {
      scale: t("screens.Measures.scale"),
      category: t("screens.Measures.category"),
      container: t("screens.Measures.container"),
      device: t("screens.Measures.device"),
      measurementType: t("screens.Measures.measurementType"),
      tare: t("screens.Measures.tare"),
      submit: t("screens.Measures.submit"),
      dashboard: t("screens.Measures.dashboard"),
      newMeasurement: t("screens.Measures.newMeasurement"),
      volume: t("screens.Measures.volume"),
      dimensions: t("screens.Measures.dimensions"),
      time: t("screens.Measures.time"),
      download3DView: t("screens.Measures.download3DView"),
      errorMessageSubmit: t("screens.Measures.errorMessageSubmit"),
      errorMessageUnknownDevice: t(
        "screens.Measures.errorMessageUnknownDevice"
      ),
      errorMessageUnknownContainer: t(
        "screens.Measures.errorMessageUnknownContainer"
      ),
      errorMessageUnboundTimeout: t(
        "screens.Measures.errorMessageUnboundTimeout"
      ),
      errorMessageUnauthorizedRequest: t(
        "screens.Measures.errorMessageUnauthorizedRequest"
      ),
      errorMessageDeviceNotReady: t(
        "screens.Measures.errorMessageDeviceNotReady"
      ),
      errorMessageVolumeComputation: t(
        "screens.Measures.errorMessageVolumeComputation"
      ),
      errorMessageUnexpected: t("screens.Measures.errorMessageUnexpected"),
      errorMessageUnknownUsecase: t(
        "screens.Measures.errorMessageUnknownUsecase"
      ),
      errorMessageUnboundDelay: t("screens.Measures.errorMessageUnboundDelay"),
      errorMessageInvalidContentType: t(
        "screens.Measures.errorMessageInvalidContentType"
      ),
    },
    Home: {
      emptyServiceList: t("screens.Home.emptyServiceList"),
      emptyCompletedServices: t("screens.Home.emptyCompletedServices"),
      scheduled: t("screens.Home.scheduled"),
      completed: t("screens.Home.completed"),
      version: t("screens.Home.version"),
      helpPage: t("screens.Home.helpPage"),
      help: t("screens.Home.help"),
    },
    CreateService: {
      header: t("screens.CreateService.header"),
      orderNumber: t("screens.CreateService.orderNumber"),
      register: t("screens.CreateService.register"),
      cancel: t("screens.CreateService.cancel"),
    },
    ServiceCategories: {
      category: t("screens.ServiceCategories.category"),
      measured: t("screens.ServiceCategories.measured"),
      completeService: t("screens.ServiceCategories.completeService"),
      emptyCategoriesList: t("screens.ServiceCategories.emptyCategoriesList"),
    },
    ServiceMeasurements: {
      declared: t("screens.ServiceMeasurements.declared"),
      measured: t("screens.ServiceMeasurements.measured"),
      measurement: t("screens.ServiceMeasurements.measurement"),
      volume: t("screens.ServiceMeasurements.volume"),
      newMeasurement: t("screens.ServiceMeasurements.newMeasurement"),
      emptyMeasurementsList: t(
        "screens.ServiceMeasurements.emptyMeasurementsList"
      ),
      container: t("screens.ServiceMeasurements.container"),
      device: t("screens.ServiceMeasurements.device"),
    },
  },
  cognito: {
    SignIn: t(["cognito", "SignIn"]),
    SignOut: t(["cognito", "SignOut"]),
    Username: t(["cognito", "Username"]),
    Password: t(["cognito", "Password"]),
    Email: t(["cognito", "Email"]),
    Confirm: t(["cognito", "Confirm"]),
    Cancel: t(["cognito", "Cancel"]),
    Submit: t(["cognito", "Submit"]),
    Skip: t(["cognito", "Skip"]),
    Verify: t(["cognito", "Verify"]),
    Code: t(["cognito", "Code"]),
    Anonymous: t(["cognito", "Anonymous"]),
    IncorrectLogin: t(["cognito", "IncorrectLogin"]),
    ChangePassword: t(["cognito", "ChangePassword"]),
    OldPassword: t(["cognito", "OldPassword"]),
    NewPassword: t(["cognito", "NewPassword"]),
    ConfirmPassword: t(["cognito", "ConfirmPassword"]),
    ForgotPassword: t(["cognito", "ForgotPassword"]),
    EmailConfirmCode: t(["cognito", "EmailConfirmCode"]),
    ConfirmationCode: t(["cognito", "ConfirmationCode"]),
    FirstAccess: t(["cognito", "FirstAccess"]),
    SuccessMessageChangePassword: t([
      "cognito",
      "SuccessMessageChangePassword",
    ]),
    SuccessMessageSubmit: t(["cognito", "SuccessMessageSubmit"]),
    SuccessMessageTara: t(["cognito", "SuccessMessageTara"]),
    ErrorMessageDifferentPasswords: t([
      "cognito",
      "ErrorMessageDifferentPasswords",
    ]),
    ErrorEmptyFieldsMessage: t(["cognito", "ErrorEmptyFieldsMessage"]),
    ErrorGeneralMessage: t(["cognito", "ErrorGeneralMessage"]),
    NotAuthorizedException: t(["cognito", "NotAuthorizedException"]),
    UserNotFoundException: t(["cognito", "UserNotFoundException"]),
    CodeMismatchException: t(["cognito", "CodeMismatchException"]),
    InvalidPasswordException: t(["cognito", "InvalidPasswordException"]),
    InvalidParameterException: t(["cognito", "InvalidParameterException"]),
    PasswordResetRequiredException: t([
      "cognito",
      "PasswordResetRequiredException",
    ]),
  },
  popups: {
    errors: {
      computationOngoing: t("popups.errors.computationOngoing"),
      computationTimeout: t("popups.errors.computationTimeout"),
      downloadErrorTitle: t("popups.errors.downloadErrorTitle"),
      downloadErrorText: t("popups.errors.downloadErrorText"),
      fetchErrorTitle: t("popups.errors.fetchErrorTitle"),
      fetchErrorText: t("popups.errors.fetchErrorText"),
      registrationErrorTitle: t("popups.errors.registrationErrorTitle"),
      registrationErrorText: t("popups.errors.registrationErrorText"),
      updateErrorTitle: t("popups.errors.updateErrorTitle"),
      updateErrorText: t("popups.errors.updateErrorText"),
      measurementErrorTitle: t("popups.errors.measurementErrorTitle"),
      measurementDeleteErrorTitle: t(
        "popups.errors.measurementDeleteErrorTitle"
      ),
      measurementDeleteErrorText: t("popups.errors.measurementDeleteErrorText"),
      measurementPatchErrorTitle: t("popups.errors.measurementPatchErrorTitle"),
      measurementPatchErrorText: t("popups.errors.measurementPatchErrorText"),
      orderNumberCanNotBeEmpty: t("popups.errors.orderNumberCanNotBeEmpty"),
      orderNumberTooLong: t("popups.errors.orderNumberTooLong"),
      volumeCanNotBeEmpty: t("popups.errors.volumeCanNotBeEmpty"),
      volumeHasInvalidChars: t("popups.errors.volumeHasInvalidChars"),
      volumeInWrongFormat: t("popups.errors.volumeInWrongFormat"),
      volumeInvalid: t("popups.errors.volumeInvalid"),
      volumeOutOfBounds: t("popups.errors.volumeOutOfBounds"),
    },
    success: {
      volume: t("popups.success.volume"),
      deletedVolumeTitle: t("popups.success.deletedVolumeTitle"),
    },
  },
};

i18nJs.enableFallback = true;
i18nJs.translations = { en, pt, es };
i18nJs.locale = Localization.locale;

export const i18n = i18nJs;
export default i18nDictionary;
